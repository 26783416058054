import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { title: "PROPAT - Login" },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/recuperar/:url",
    name: "recuperar",
    meta: { title: "PROPAT - Recuperar Password" },
    component: () => import("../views/RecuperarPassword.vue"),
  },
  {
    path: "/admin/usuarios",
    name: "usuarios",
    meta: { title: "PROPAT - Usuarios" },
    component: () => import("../views/admin/Usuarios.vue"),
  },
  {
    path: "/admin/vigilancias",
    name: "vigilancias",
    meta: { title: "PROPAT - Vigilancias" },
    component: () => import("../views/admin/Vigilancias.vue"),
  },
  {
    path: "/admin/empresas",
    name: "empresas",
    meta: { title: "PROPAT - Empresas" },
    component: () => import("../views/admin/Empresas.vue"),
  },
  {
    path: "/",
    name: "eventos",
    meta: { title: "PROPAT - Eventos" },
    component: () => import("../views/Eventos.vue"),
  },
  {
    path: "/mensajes",
    name: "mensajes",
    meta: { title: "PROPAT - Mensajes" },
    component: () => import("../views/Mensajes.vue"),
  },
  {
    path: "/turnos",
    name: "turnos",
    meta: { title: "PROPAT - Turnos" },
    component: () => import("../views/Turnos.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <div id="app">
    <router-view />
    <SOS v-if="mostrarSOS"></SOS>
  </div>
</template>

<script>
import SOS from "@/components/SOS.vue";

export default {
  data() {
    return {
      mostrarSOS: false,
    };
  },
  components: { SOS },
  created() {
    var token = localStorage.getItem("$token");
    if (token != null) {
      this.$axios.defaults.headers.common["Authorization"] = "Token " + token;
    }
  },
  mounted() {
    console.log("2023-01-04");
    console.log(this.$serverURL);

    this.$emitter.on("logged", () => {
      this.getSelf();
    });
    this.$emitter.on("logout", () => {
      this.mostrarSOS = false;
    });

    let token = localStorage.getItem("$token");
    if (token != null) {
      this.$axios.defaults.headers.common["Authorization"] = "Token " + token;
    }

    this.getSelf();
  },
  methods: {
    getSelf() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/user/me/")
        .then(function (response) {
          if (response.data.id != null) {
            that.$store.commit("setUsuario", response.data);
            that.$emitter.emit("update");
            that.mostrarSOS = true;
          }
        })
        .catch(function () {
          // handle error
          if (
            window.location.pathname != "/login" &&
            !window.location.pathname.split("/").some((p) => p == "recuperar")
          ) {
            that.$router.push("/login");
          }
        });
    },
  },
};
</script>

<style lang="scss" src="@/assets/css/app.scss">
</style>
<style lang="scss" src="@/assets/css/transitions.scss">
</style>

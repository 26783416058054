<template>
  <button class="sos" @click="sos()">S.O.S</button>
</template>

<script>
export default {
  name: "SOS",
  props: {},
  components: {},
  mounted() {},
  computed: {},
  methods: {
    sos() {
      console.log("sos");
      let fecha = new Date();
      let hora = fecha.getHours().toString();
      if (hora.length == 1) {
        hora = "0" + hora;
      }
      let minuto = fecha.getMinutes().toString();
      if (minuto.length == 1) {
        minuto = "0" + minuto;
      }
      let json = {
        tipo: "boton_panico",
        titulo: this.tituloEvento,
        horario_suceso: hora + ":" + minuto,
        ubicacion_alternativa: "",
      };
      this.$axios
        .post(this.$serverURL + "/api/eventos/", json)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/components/sos.scss"></style>

import { createStore } from "vuex";

export default createStore({
  state: {
    usuario: null,
  },
  mutations: {
    // setters
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
  },
  actions: {},
  modules: {},
  getters: {
    usuario(state) {
      return state.usuario;
    },
  },
});

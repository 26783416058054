import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import mitt from "mitt";

import Toast from "vue-toastification";
import { useToast } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const app = createApp(App).use(store).use(router);
const emitter = mitt();

app.use(Toast, {
  position: "bottom-right",
  transition: "Vue-Toastification__fade",
  timeout: 4000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  icon: false,
  rtl: false,
});

emitter.on("toast-error", (text) => {
  const toast = useToast();
  toast.error(text);
});

app.config.globalProperties.$serverURL = process.env.VUE_APP_URL;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$emitter = emitter;

app.mount("#app");
